/**
 * Image Slider
 */

import Splide from '@splidejs/splide';

const newsSlider = {
  cfg: {
    slider: '.js-newsSlider',
    sliderOptions: {
      type: 'loop', // 'fade' 'loop' 'carousel'
      rewind: true, // Determines whether to rewind the carousel or not
      speed: 400, // The transition speed in milliseconds
      rewindSpeed: 400, //	The transition speed on rewind in milliseconds, The speed value is used as default.
    //   rewindByDrag: false,	// Allows to rewind by drag
      width: '100%',	// Defines the carousel max width, accepting the CSS format such as 10em, 80vw
      // height: '100%', // Defines the carousel height, accepting the CSS format except for %
    //   fixedWidth: '20%', // Fixes width of slides, accepting the CSS format
      // fixedHeight: false, //	Fixes height of slides, accepting the CSS format except for %
      // heightRatio: false, // Determines height of slides by the ratio to the carousel width
      // autoWidth: true, // If true, the width of slides are determined by their width
      // autoHeight: true, // If true, the height of slides are determined by their height
      // start: 0, // The start index
      perPage: 3, // Determines the number of slides to display in a page
      // perMove: 1, // Determines the number of slides to move at once
      // clones: , // Explicitly determines the number of clones on each side of the carousel
      // cloneStatus:, // Determines whether to add the is-active class to clones
      // focus: 'center', // Determines which slide should be active if there are multiple slides in a page
      gap: '1rem', // The gap between slides. The CSS format is acceptable
      // padding: { left: '2rem', right: '2rem' }, // Sets padding left/right or top/bottom of the carousel. The CSS format is acceptable
      arrows: false, // Determines whether to create/find arrows or not
      pagination: false, // Determines whether to create pagination (indicator dots) or not
      // paginationKeyboard:, // Determines whether to enable keyboard shortcuts for pagination when it contains focus
      // paginationDirection:, // Explicitly sets the pagination direction
      // easing:, // The timing function for the CSS transition
      // easingFunc:, // The easing function for the drag free mode
      drag: 'free', // Determines whether to allow to drag the carousel or not
      snap: true, // Snaps the closest slide on drag free mode
      // noDrag:, // The selector for nodes that cannot be dragged.
      // dragMinThreshold:, // The required distance to start moving the carousel by the touch action
      // flickPower:, // Determines the power of "flick". The larger number this is, the farther the carousel runs
      // flickMaxPages:, // Limits the number of pages to move by the flick action
      // waitForTransition:, // Determines whether to disable any actions while the carousel is transitioning
      // arrowPath:, // Changes the arrow SVG path, like 'm7.61 0.807-2.12...'
      autoplay: false, // Determines whether to activate autoplay or not
      // interval: 3000, // The autoplay interval in milliseconds
      // pauseOnHover:, // Determines whether to pause autoplay on mouseover or not
      // pauseOnFocus:, // Determines whether to pause autoplay when the carousel contains a focused element or not
      // resetProgress:, // Determines whether to reset the autoplay progress when it is requested to start again or not
      // lazyLoad:, // Enables lazy loading
      // preloadPages:, // Determines how many pages (not slides) around the active slide should be loaded beforehand
      // keyboard:, // Enables keyboard shortcuts
      // wheel:, // Enables navigation by the mouse wheel
      // wheelMinThreshold:, // The threshold to cut off the small delta produced by inertia scroll
      // wheelSleep:, // The sleep duration in milliseconds until accepting next wheel input
      // releaseWheel:, // Determines whether to release the wheel event when the carousel reaches the first or last slide
      // direction:, // The direction of the carousel
      // cover:, // Converts the image src to the css background-image URL of the parent element
      // slideFocus:, // Determines whether to add tabindex="0" to visible slides or not
      // focusableNodes:, // A selector for focusable nodes inside slides.
      // isNavigation:, // If true, the carousel makes slides clickable to navigate another carousel
      trimSpace: false, // Determines whether to trim spaces before/after the carousel if the focus option is available
      // omitEnd:, // Disables the next arrow when the carousel reaches the last page and omits redundant pagination dots(^4.1.0)
      // updateOnMove:, // Updates the is-active status of slides just before moving the carousel
      // mediaQuery:, // If min, the media query for breakpoints will be min-width, or otherwise, max-width
      // live:, // Enables a live region
      // breakpoints:, // The collection of responsive options for specific breakpoints
      // reducedMotion:, // Options used when the (prefers-reduced-motion: reduce) is detected
      // classes:, // The collection of class names
      // i18n:, // The collection of i18n strings
      // destroy:, // Destroys the carousel
    }
  },

  init() {
    window.addEventListener('load', () => {
      const sliders = [...document.querySelectorAll(this.cfg.slider)]
      if (sliders) {
        sliders.forEach((slider) => {

          const splideEl = slider.querySelector('.this-slider'); 
          const splide = new Splide(splideEl, this.cfg.sliderOptions).mount()
          const customNav = slider.querySelector('.this-nav');

          if(customNav){
            customNav.querySelector('.is-prev').addEventListener('click', () => {
              splide.go('-1')
            });
            customNav.querySelector('.is-next').addEventListener('click', () => {
              splide.go('+1')
            });
          } 
        })
      }
    })
  }
}

export default newsSlider
