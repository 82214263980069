/**
 * Image Slider
 */

import Flickity from 'flickity-fade'

const projectSlider = {
  cfg: {
    slider: '.js-projectSlider',
    sliderOptions: {
      cellAlign: 'left',
      contain: true,
      draggable: true,
      friction: 1,
      lazyLoad: 2,
      selectedAttraction: 0.1,
      groupCells: 3,
      setGallerySize: true,
      imagesLoaded: true,
      prevNextButtons: true,
      pageDots: false,
      wrapAround: false
      // watchCSS: true
    }
  },

  init() {
    window.addEventListener('load', () => {
      const sliders = [...document.querySelectorAll(this.cfg.slider)]
      if (sliders) {
        sliders.forEach((slider) => {

        //   const groupCells =  slider.dataset.slides;
        //   console.log(groupCells);
        //   let cells;

        //   if (groupCells === 'one') {
        //     cells = 1;
        //   } else if (groupCells === 'two') {
        //     cells = 2;
        //   } else if (groupCells === 'three') {
        //     cells = 3;
        //   } else if (groupCells === 'four') {
        //     cells = 4;
        //   }

        //   this.cfg.sliderOptions.groupCells = cells;
        //   console.log(cells);

          const flkty = new Flickity(slider, this.cfg.sliderOptions)
          flkty.resize()

          setTimeout(() => {
            flkty.resize()
            slider.classList.add('is-ready')
          }, 1000)
        })
      }
    })
  }
}

export default projectSlider
