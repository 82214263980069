const map = {
  cfg: {
    name: 'map'
  },

  createMapHead() {
    const jsCDN = 'https://api.mapbox.com/mapbox-gl-js/v2.14.1/mapbox-gl.js';
    const cssCDN = 'https://api.mapbox.com/mapbox-gl-js/v2.14.1/mapbox-gl.css';

    let scriptTag = document.createElement("script");
    var linkTag = document.createElement("link");

    scriptTag.type = "text/javascript";
    linkTag.rel = "stylesheet";

    scriptTag.src = jsCDN;
    linkTag.href = cssCDN;

    document.getElementsByTagName("head")[0].appendChild(scriptTag);
    document.getElementsByTagName("head")[0].appendChild(linkTag);

    return false;
  },

  loadMapbox() {
    let mapboxgl;

    mapboxgl.accessToken =
    'pk.eyJ1Ijoic3Rhcmtjb2RlcyIsImEiOiJjam9wemc4ZzEwMW12M2twMnZ6ODM3dThpIn0.D8t6MpHi-9gLGA7hFyzYuQ';
    var bounds = new mapboxgl.LngLatBounds();

    const geojson = {
        'type': 'FeatureCollection',
        'features': [
            {
                'type': 'Feature',
                'geometry': {
                    'type': 'Point',
                    'coordinates': [9.467978, 51.317437]
                },
                'properties': {
                    'title': 'Wäschereiquartier',
                    'description': 'Dörnbergstraße 18-20<br>34119 Kassel'
                }
            }
        ]
    };

    geojson.features.forEach(function (feature) {
        bounds.extend(feature.geometry.coordinates);
    });

    const map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/starkcodes/clgmojfye00cn01o1do4z5yre',
        center: [ 9.467978, 51.317437],
        // minZoom: 8,
        maxZoom: 18,
        pitch: 60, // pitch in degrees
        // bearing: -60, // bearing in degrees
        zoom: 16
        // maxBounds:  [[2.38865807458, 45.3024876979],
        //             [17.0169958839,57]]
    });

    // add markers to map
    for (const feature of geojson.features) {
        // create a HTML element for each feature
        const el = document.createElement('div');
        el.className = 'marker';

        var markerWidth = 5;
        var markerHeight = 5;

        el.style.width = markerWidth + 'px';
        el.style.height = markerHeight + 'px';

        // make a marker for each feature and add it to the map
        new mapboxgl.Marker(el)
            .setLngLat(feature.geometry.coordinates)
            .setPopup(
                new mapboxgl.Popup({
                    offset: 15,
                    closeOnClick: false
                }) // add popups
                .setHTML(
                    `<h3>${feature.properties.title}</h3>
                    <p>${feature.properties.description}</p>`
                )
            )
            .addTo(map)
            .togglePopup();
    }

    // map.fitBounds(bounds, {
    //     padding: {
    //         top: 160,
    //         bottom: 120,
    //         left: 40,
    //         right: 40
    //     }
    // });

    // map.setMaxBounds(bounds);

    // map.fitBounds([5.98865807458, 49.3024876979, 15.0169958839, 54.983104153], {
    //     padding: {
    //         top: 180,
    //         bottom: 40,
    //         left: 40,
    //         right: 40
    //     }
    // });

    map.scrollZoom.disable();

    // TODO: CLEANUP YOUR F*CKIN CODE
    // VORDERER WESTERN
    document.getElementById('map_wq').addEventListener('click', function()  {
        this.classList.add('is-active');
        document.getElementById('map_vw').classList.remove('is-active');
        document.getElementById('map_ksl').classList.remove('is-active');

        // Fly to a random location
        map.flyTo({
            // center: [ 9.467978, 51.317437],
            zoom: 18,
            speed: 0.5,
            pitch: 60,
        });
    });

    // VORDERER WESTERN
    document.getElementById('map_vw').addEventListener('click', function() {
        this.classList.add('is-active');
        document.getElementById('map_wq').classList.remove('is-active');
        document.getElementById('map_ksl').classList.remove('is-active');

        // Fly to a random location
        map.flyTo({
            // center: [ 9.467978, 51.317437],
            zoom: 16,
            speed: 0.5,
            pitch: 60,
        });
    });

    // KASSEL
    document.getElementById('map_ksl').addEventListener('click', function()  {
        this.classList.add('is-active');
        document.getElementById('map_wq').classList.remove('is-active');
        document.getElementById('map_vw').classList.remove('is-active');
        // Fly to a random location
        map.flyTo({
            // center: [ 9.467978, 51.317437],
            zoom: 13,
            speed: 0.5,
            pitch: 40,
        });
    });

    // map.touchZoomRotate.enable();
  },

  init() {
    window.addEventListener('load', () => {
        
        
        const maps = document.querySelectorAll('.o-map');

        let tagsVisible;
        const gdpr = false;
        
        if (maps) {
    
            tagsVisible = false;
    
            maps.forEach((map) => {

                const button = map.querySelector('[data-privacy-button]');
    
                if(gdpr) {
                    button.onclick = () => {
                        map.classList.add('has-privacy-accepted');
        
                        if (!tagsVisible) {
                            this.createMapHead();
                            tagsVisible = true;
                        }
    
                        setTimeout(() =>{
                            this.loadMapbox();
                        },1000)
                    };
                } else {
                    map.classList.add('has-privacy-accepted');
                    
                    this.createMapHead();
                    
                    setTimeout(() =>{
                        this.loadMapbox();
                    },1000)
                }

               
            });
        }
    })
  }
}

export default map;