/**
 * microModal
 */
import MicroModal from 'micromodal'

const microModal = {
  init() {
    window.addEventListener('load', () => {
      MicroModal.init({
        disableFocus: true,
        disableScroll: true,
        awaitCloseAnimation: true,
        closeTrigger: 'data-micromodal-close'
      })
    })
  }
}

export default microModal
